export const exploreOptions = [
  {
    id: "world-1",
    imgUrl:
      "https://firebasestorage.googleapis.com/v0/b/livefitfitnessstudio-2c22c.appspot.com/o/sliding_images_New%2Fsliderimage3.jpg?alt=media&token=5bb92168-4eba-4fc6-b833-0e3a0c100f1f",
    title: "1-1 Coaching",
  },
  {
    id: "world-2",
    imgUrl:
      "https://firebasestorage.googleapis.com/v0/b/livefitfitnessstudio-2c22c.appspot.com/o/sliding_images_New%2Fsliderimage1.jpg?alt=media&token=d2c5139d-c2fc-49ce-a973-8f217caad446",
    title: "Supplementation Details",
  },
  {
    id: "world-3",
    imgUrl:
      "https://firebasestorage.googleapis.com/v0/b/livefitfitnessstudio-2c22c.appspot.com/o/sliding_images_New%2Fsliderimage2.jpg?alt=media&token=37099d2c-e58d-462b-9e13-4fd8981beafe",
    title: "Transformation Challenge",
  },
];
