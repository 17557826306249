import React, { useState } from 'react';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';

import './popup.css';
import { IconButton } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';

const Popup = (props) => {

    const {handleClose, open, title, children, message} = props;

    return(
        <Dialog onClose={handleClose} open={open}>
            {title? <DialogTitle className='dialogTitle'>
                <span className='titletext'>{title}</span>
                
                <IconButton onClick={handleClose} >
                    <CloseIcon/>
                </IconButton>
            </DialogTitle> : ''}
            {children ? children : ''}
            {message ? 
            <div className='message'>
                {message}
            </div>
             : ''}
        </Dialog>
    );
}

export default Popup;