export const initialState = {
  admin: null,
  openEdit: false,
  users: null,
  currentId: null,
  openDelete: false,
  openView: false,
};

const reducer = (state, action) => {
  console.log("reducers ");
  console.log(action);
  console.log(state);
  switch (action.type) {
    case "SET_ADMIN":
      console.log("set_admin");
      return { ...state, admin: action.admin };
      break;
    case "SET_OPEN":
      console.log("set_open");
      return {
        ...state,
        openEdit: action.openEdit,
      };
      break;
    case "SET_USERS":
      console.log("set_users");
      return {
        ...state,
        users: action.users,
      };
    case "SET_CURRENT_USER_ID":
      console.log("set current user id");
      return {
        ...state,
        currentId: action.currentId,
      };
    case "SET_OPEN_DELETE":
      console.log("set_open_delete");
      return {
        ...state,
        openDelete: action.openDelete,
      };

    case "SET_OPEN_VIEW":
      console.log("set_open_view ");
      return {
        ...state,
        openView: action.openView,
      };

    default:
      console.log("default");
      return state;
  }
};

export default reducer;
