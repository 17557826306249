import { Box, Button } from "@mui/material";
import React, { useState, useEffect } from "react";
import { QrReader } from "react-qr-reader";
import Header from "../Header";
import { useStateValue } from "./StateProvider";
import { useNavigate } from "react-router-dom";
import { onAuthStateChanged } from "firebase/auth";
import { auth, db } from "./firebase";
import { addDoc, serverTimestamp, collection, getDoc, getDocs } from "firebase/firestore";

const pages = ["Users"];
function QRScan() {
  const [data, setData] = useState(null);
  const [{ admin, users }, dispatch] = useStateValue();
  const navigate = useNavigate();

  useEffect(() => {
    onAuthStateChanged(auth, (data) => {
      console.log("On authstate changed");
      if (data) {
        console.log("logged in");
        console.log(data);
        dispatch({
          type: "SET_ADMIN",
          admin: data,
        });
        console.log(auth);
        navigate("/qrscan");
      } else {
        console.log("Not logged in");
        navigate("/login");
      }
    });
  }, []);

  // const handleScan = data => {
  //     console.log("handleScan")
  //   if (data) {
  //     setResult(data);
  //   }
  // };

  // const handleError = error => {
  //   console.error(error);
  // };

  const handleResult = (result, error) => {
    if (!!result) {
      setData(result?.text);
      //masthan:9790052223
    }

    //   if (!!error) {
    //     console.info(error);
    //   }
  };

  const handleUpdateLog = () => {

    const data = {
      entry : serverTimestamp()
    };

    const userRef = collection(db, "users", "1qHB8agJ8SUOZruyUM4Y", "log");
    console.log(userRef);

    addDoc(userRef, data)
      .then((e) => {
        console.log("success");
        console.log(e);
      }).catch((e) => {
        console.log(e);
      })

  };

  // loading log data -> should be moved to users screen
  
  useEffect(() => {
    console.log("Load log :")
    const logRef = collection(db, "users","1qHB8agJ8SUOZruyUM4Y","log");
    // const lg = collection(db, "users")

    getDocs(logRef)
      .then((data) => {
        console.log("Success");
        console.log(data.docs.map(d => ({id: d.id, ...d.data()})))
      }).catch((e) => {
        console.log(e);
      })
  },[])

  return (
    <Box sx={{ display: "flex", flexDirection: "column" }}>
      {/* <Header pages={pages} user={true} /> */}
      {/* <Box
        sx={{
          marginTop: "50px",
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
        }}
      >
        <Box sx={{ width: "50%", height: "50%" }}>
          <QrReader
            onResult={handleResult}
            style={{ width: '100%' }}
          />
        </Box>
        <p>{data ? `Result: ${data}` : "No result"}</p>
      </Box> */}
      <Button onClick={handleUpdateLog} sx={{ my: 2, color: 'white', display: 'block' }} >Test</Button>
    </Box>
  );
}

export default QRScan;
