import React, { useEffect, useState } from 'react'
import readXlsxFile from 'read-excel-file'

import {
  addDoc,
  collection,
  serverTimestamp,
  doc,
  updateDoc,
  deleteDoc,
} from "firebase/firestore";
import { db } from '../admin/firebase';

function Batch() {
const [input, setInput] = useState(null);
const [data, setData] = useState(null);

useEffect(() => {

    if(input === null || input === undefined) {
        return;
    }
    readXlsxFile(input).then((rows) => {
        // `rows` is an array of rows
        // each row being an array of cells.
        console.log(rows)
        setData(rows);
      }).catch((e) => {
        console.log(e);
      });

}, [input])

useEffect(() => {

    if(data === null || data === undefined) {
        return;
    }
    console.log("Data set... ",data);

    data.forEach(d => {

        const tdata = {
            col1 : d[0],
            col2 : d[1],
            col3 : d[2]
        };
    
        console.log("Tdata.... ", tdata)
    
        const userRef = collection(db, "test");
    
        addDoc(userRef, tdata)
          .then((e) => { 
            console.log("Success :",e)
          }).catch((e) => {
            console.log("error", e)
          });
        
    });
    

}, [data])

  return (
    <div>
      <input type='file' id='file' onChange={(e) => setInput(e.target.files[0])}/>
    </div>
  )
}

export default Batch
