import * as React from 'react';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import Menu from '@mui/material/Menu';
import MenuIcon from '@mui/icons-material/Menu';
import Container from '@mui/material/Container';
import Avatar from '@mui/material/Avatar';
import Button from '@mui/material/Button';
import Tooltip from '@mui/material/Tooltip';
import MenuItem from '@mui/material/MenuItem';
import { Link } from '@mui/material';
import {Link as Lnk } from 'react-scroll';
import LogoutIcon from '@mui/icons-material/Logout';
import { signOut } from 'firebase/auth';
import { auth } from './admin/firebase';
import { useNavigate } from "react-router-dom";
import { useStateValue } from './admin/StateProvider';

function Header(props) {
  const {pages, user} = props;
  const [anchorElNav, setAnchorElNav] = React.useState(null);
  const navigate = useNavigate();
  const [{admin}, dispatch] = useStateValue();

  const handleOpenNavMenu = (event) => {
    setAnchorElNav(event.currentTarget);
  };

  const handleCloseNavMenu = (page = '') => {
    if(props?.externalLink) {
      navigate(`/${page.toLowerCase()}`)
    } else {
      setAnchorElNav(null);
    }
    
  };

  function logOut() {
    signOut(auth).then(e => {
      dispatch({
        type: "SET_USER",
        admin: null,
      });
      navigate("/login");
    }).catch(e => alert(e));
  }


  return (
    <AppBar position='fixed' style={{ background: 'black' }}>
      <Container maxWidth='xl'>
      <Toolbar disableGutters>
        {user && (
          <Link href='/' style={{textDecoration:'none'}}>
          <Box
        component="img"
        sx={{ height: 54, display: { xs: 'none', md: 'flex' }, mr: 1}}
        alt="Logo"
        src="https://firebasestorage.googleapis.com/v0/b/livefitfitnessstudio-2c22c.appspot.com/o/E4F3F9CB-943A-46D5-A5B5-C4D722E1336B.jpeg?alt=media&token=a806a313-4c5d-409f-b88a-3a02162b893f"
          />
          </Link>
        )}
        { !user && (
            <Lnk to="banner" spy={true} smooth={true} duration={500}>
            <Box
              component="img"
              sx={{ height: 54, display: { xs: 'none', md: 'flex' }, mr: 1, cursor : 'pointer'}}
              alt="Logo"
              src="https://firebasestorage.googleapis.com/v0/b/livefitfitnessstudio-2c22c.appspot.com/o/E4F3F9CB-943A-46D5-A5B5-C4D722E1336B.jpeg?alt=media&token=a806a313-4c5d-409f-b88a-3a02162b893f"
            />
          </Lnk>
        )}
      
          <Box sx={{ flexGrow: 1, display: { xs: 'flex', md: 'none' } }}>
            <IconButton
              size="large"
              aria-label="account of current user"
              aria-controls="menu-appbar"
              aria-haspopup="true"
              onClick={handleOpenNavMenu}
              color="inherit"
            >
              <MenuIcon />
            </IconButton>
            <Menu
              id="menu-appbar"
              anchorEl={anchorElNav}
              anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'left',
              }}
              keepMounted
              transformOrigin={{
                vertical: 'top',
                horizontal: 'left',
              }}
              open={Boolean(anchorElNav)}
              onClose={handleCloseNavMenu}
              sx={{
                display: { xs: 'block', md: 'none' },
              }}
            >
              {pages.map((page) => (
                <Lnk key={page} to={`${page}`} spy={true} smooth={true} offset={-50} duration={500}>
                <MenuItem key={page} onClick={() => {handleCloseNavMenu(page)}}>
                  <Typography textAlign="center">{page}</Typography>
                </MenuItem>
                </Lnk>
              ))}
            </Menu>
          </Box>
          
          <Lnk to="banner" spy={true} smooth={true} offset={-50} duration={500} style={{flexGrow:1}}>
      <Box
        component="img"
        sx={{ height: 54, flexGrow:1, display: { xs: 'flex', md: 'none'}}}
        alt="Logo"
        src="https://firebasestorage.googleapis.com/v0/b/livefitfitnessstudio-2c22c.appspot.com/o/E4F3F9CB-943A-46D5-A5B5-C4D722E1336B.jpeg?alt=media&token=a806a313-4c5d-409f-b88a-3a02162b893f"
      />
    </Lnk>
          <Box sx={{ justifyContent: props?.positionExternalLinkRight? 'flex-end' : 'center', flexGrow:1, display: { xs: 'none', md: 'flex' } }}>
            {pages.map((page) => (
              <Lnk key={page} to={`${page}`} spy={true} smooth={true} offset={-50} duration={500}>
              <Button
                key={page}
                onClick={() => {handleCloseNavMenu(page)}}
                sx={{ my: 2, color: 'white', display: 'block' }}
              >
                {page}
              </Button>
              </Lnk>
            ))}
          </Box>

          <Box sx={{ flexGrow: 0 }}>
            { user && (
              <div>
                {admin && admin.email}
              <IconButton onClick={logOut}>
                <LogoutIcon sx={{color:"white"}}/>
              </IconButton>
              </div>
            )}
            {/* enable when login feature enabled */}
            { !user && (<Tooltip title="Login">
            <Link href='/login' style={{textDecoration:'none'}}>
              <IconButton sx={{ p: 0 }}>
                <Avatar alt={admin?.email} src="/static/images/avatar/2.jpg" />
              </IconButton>
              </Link>
            </Tooltip>)}
          </Box>
        </Toolbar>

      </Container>

    </AppBar>
  )
}

export default Header