
import Slider from "react-slick";

import './slick.css';


const IMG1 = 'https://firebasestorage.googleapis.com/v0/b/livefitfitnessstudio-2c22c.appspot.com/o/sliding_images_New%2Fsliderimage9.jpg?alt=media&token=1225e4d6-a974-4671-986c-983869ac9cfd';
const IMG2 = 'https://firebasestorage.googleapis.com/v0/b/livefitfitnessstudio-2c22c.appspot.com/o/sliding_images_New%2Fsliderimage19.jpg?alt=media&token=c38de7ed-6259-46da-bb56-92004df24a46';
const IMG3 = 'https://firebasestorage.googleapis.com/v0/b/livefitfitnessstudio-2c22c.appspot.com/o/sliding_images_New%2Fsliderimage4.jpg?alt=media&token=b5e61738-1e20-4af2-85a6-3a9e9c49d94a';
const IMG4 = 'https://firebasestorage.googleapis.com/v0/b/livefitfitnessstudio-2c22c.appspot.com/o/sliding_images_New%2Fsliderimage17.jpg?alt=media&token=56051037-3965-498f-a338-2a64a26da227';
const IMG5 = 'https://firebasestorage.googleapis.com/v0/b/livefitfitnessstudio-2c22c.appspot.com/o/sliding_images_New%2Fsliderimage1.jpg?alt=media&token=d2c5139d-c2fc-49ce-a973-8f217caad446';
const IMG6 = 'https://firebasestorage.googleapis.com/v0/b/livefitfitnessstudio-2c22c.appspot.com/o/sliding_images_New%2Fsliderimage11.jpg?alt=media&token=5d4f5d4a-d181-470c-9b97-e1293881f3b5';
const IMG7 = 'https://firebasestorage.googleapis.com/v0/b/livefitfitnessstudio-2c22c.appspot.com/o/sliding_images_New%2Fsliderimage3.jpg?alt=media&token=5bb92168-4eba-4fc6-b833-0e3a0c100f1f';
const IMG8 = 'https://firebasestorage.googleapis.com/v0/b/livefitfitnessstudio-2c22c.appspot.com/o/sliding_images_New%2Fsliderimage8.jpg?alt=media&token=06f7420e-8189-44b6-9fcc-5d1fd9e2048f';
const IMG9 = 'https://firebasestorage.googleapis.com/v0/b/livefitfitnessstudio-2c22c.appspot.com/o/sliding_images_New%2Fsliderimage10.jpg?alt=media&token=0d54d3d3-bd13-44a6-b417-bc87eb894a5a';
const settings1 = {
    arrows: false,
    slidesPerRow: 3,
    variableWidth: true,
    adaptiveHeight: true,
    dots: false,
    autoplay: true,
    autoplaySpeed: 500,
    speed: 5000,
    infinite: true,
    
  };

const settings2 = {
    arrows: false,
    slidesPerRow: 3,
    variableWidth: true,
    adaptiveHeight: true,
    dots: false,
    autoplay: true,
    autoplaySpeed: 500,
    speed: 7000,
    infinite: true,
    
  };

const settings3 = {
    arrows: false,
    slidesPerRow: 3,
    variableWidth: true,
    adaptiveHeight: true,
    dots: false,
    autoplay: true,
    autoplaySpeed: 500,
    speed: 6000,
    infinite: true,
  };


const sliderWeb = (settings, image1, image2, image3) => {
    return (
        <Slider  {...settings}>
              <div >
                <img alt="Hello" src={image2} width= {`90%`} height="300" loading="lazy"/>
              </div>

              <div >
                <img alt="Hello" src={image1} width={`90%`} height="300"/>
              </div>

              <div >
                <img alt="Hello" src={image3} width={`90%`} height="300"/>
              </div>

              <div >
                <img alt="Hello" src={image2} width={`90%`} height="300"/>
              </div>

              <div >
                <img alt="Hello" src={image1} width={`90%`} height="300"/>
              </div>

              <div >
                <img alt="Hello" src={image3} width={`90%`} height="300"/>
              </div>
            
            </Slider>
    );
}

const sliderMobile = (settings, image1, image2, image3) => {
    settings.slidesPerRow = 1;
    return (
        <Slider  {...settings}>
              <div>
                <img className="imgtag" alt="Hello" src={image2}  height="200" />
              </div>

              <div>
                <img className="imgtag" alt="Hello" src={image1}  height="200" />
              </div>

              <div>
                <img className="imgtag" alt="Hello" src={image3}  height="200" />
              </div>            
            </Slider>
    );
}


const Sliders = (props) => {

    return (
        props.isMobileScreen ? 
        <div className="slider">
            {sliderMobile(settings1, IMG1, IMG2, IMG3)}
            {sliderMobile(settings2, IMG4, IMG5, IMG6)}
            {sliderMobile(settings3, IMG7, IMG8, IMG9)}
            <div className="textsection" >
              <div className="textvalue mobileTextvalue">
                OVER 100+ CLASSES A MONTH
              </div>
            </div>
        </div>
         : 
        <div className="slider">
        {sliderWeb(settings1, IMG1, IMG2, IMG3)}
        {sliderWeb(settings2, IMG4, IMG5, IMG6)}
        {sliderWeb(settings3, IMG7, IMG8, IMG9)}
        <div className="textsection" >
          <div className="textvalue webtextvalue">
          OVER 100+ CLASSES A MONTH
          </div>
        </div>
    </div>
        
    );
}

export default Sliders;