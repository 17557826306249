import React, {useEffect, useState} from 'react'
import { InputLabel,
  Input,
  FormControl,
  Button,
  Card,
  CardContent, } from '@mui/material';
import { createUserWithEmailAndPassword, signInWithEmailAndPassword } from 'firebase/auth';
import { auth } from "./firebase";
import { useNavigate } from "react-router-dom";
import "./Login.css";
import { useStateValue } from './StateProvider';
import { onAuthStateChanged } from 'firebase/auth';
import Popup from '../components/Popup'

function Login() {

  const [{ admin }, dispatch] = useStateValue();
  const navigate = useNavigate();
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");

  const [ popUp, setPopup] = useState({
    showPopUp : false,
    title: '',
    message: ''
  });

  const login = () => {
    signInWithEmailAndPassword(auth, email, password)
      .then((auth) => {
        dispatch({
          type: "SET_ADMIN",
          admin: auth.user,
        });
        console.log(auth);
        navigate("/users");
      })
      .catch((e) => {
        setPopup({
          showPopUp : true,
          title: 'UnAuthorized',
          message: 'Invalid UserName/Password'
        });
        // alert(e.message + " " + "Click Register..!");
      });
  };

  const register = () => {
    createUserWithEmailAndPassword(auth, email, password)
      .then((auth) => {
        dispatch({
          type: "SET_USER",
          admin: auth.user,
        });
        navigate("/users");
        console.log(auth);
      })
      .catch((e) => {
        setPopup({
          showPopUp : true,
          title: 'Error!!!',
          message: 'Unable to Create UserName/Password'
        });
          // alert(e.message)
      });
  };

  useEffect(() => {
    onAuthStateChanged(auth, (data) => {
      console.log("On authstate changed");
      if(data) {
        console.log("logged in");
        console.log(data);
        dispatch({
          type: "SET_USER",
          admin: data,
        });
        console.log(auth);
        navigate("/users");
      }else {
        console.log("Not logged in");
      }
    })
}, [])

const loginOnEnter = (event) => {

  if (event.code === 'Enter') {
    login();
  }
  
};


  return (
    <div className="login">
      <Card sx={{boxShadow : 3}}>
        <CardContent className="login_content">
          <img
            className="login__icon"
            src="https://firebasestorage.googleapis.com/v0/b/livefitfitnessstudio-2c22c.appspot.com/o/E4F3F9CB-943A-46D5-A5B5-C4D722E1336B.jpeg?alt=media&token=a806a313-4c5d-409f-b88a-3a02162b893f"
            alt="icon"
          />
          <form>
            <FormControl className="login__email">
              <InputLabel>Email</InputLabel>
              <Input
                value={email}
                onChange={(event) => {
                  setEmail(event.target.value);
                }}
              ></Input>
            </FormControl>
            <FormControl className="login__pass">
              <InputLabel>Password</InputLabel>
              <Input
                type="password"
                value={password}
                onChange={(event) => setPassword(event.target.value)}
                onKeyUp={(event) => loginOnEnter(event)}
              ></Input>
            </FormControl>
            <Button onClick={login} className="login_btn" variant="outlined">
              Sign In
            </Button>
          </form>
          
          <p className="intro">
            Enter Email, Password and Contact Admin for Register.
          </p>
        </CardContent>
      </Card>
      <Popup
        open = {popUp.showPopUp}
        title = {popUp.title}
        message = {popUp.message}
        handleClose = {() => {
          setPopup({
            showPopUp : false,
            title: '',
            message: ''
          });
        }}
      />
    </div>
  )
}

export default Login
