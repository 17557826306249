import React, { useState } from "react";
import { styled } from "@mui/material/styles";
import Button from "@mui/material/Button";
import { TextField } from "@mui/material";
import { useTheme } from "@mui/material/styles";
import useMediaQuery from "@mui/material/useMediaQuery";
import { createTheme, ThemeProvider } from "@mui/material/styles";

import {
  isValidMobileNumber,
  isValidEmailId,
  isValidName,
} from "../../utils/validation";

import { Submit } from "../../utils/formIntegration";

import "./enquiryform.css";
import { Email } from "@mui/icons-material";

const theme = createTheme({
  components: {
    MuiTextField: {
      styleOverrides: {
        root: {
          width: "100%",
          fontWeight: 600,
          lineHeight: 1.5,
          fontSize: "0.875rem",
          "@media (max-width: 576px)": {
            fontSize: "0.75rem",
          },
          "@media (min-width: 1681px)": {
            fontSize: "1.013rem",
          },
          color: "#ff0000",
        },
      },
    },
  },
});

const ColorButton = styled(Button)(({ theme }) => ({
  color: theme.palette.getContrastText("#cc0000"),
  backgroundColor: "#cc0000",
  "&:hover": {
    backgroundColor: "#980000",
  },
}));

const EnquiryForm = (props) => {
  const theme = useTheme();
  let isMobileScreen = useMediaQuery(theme.breakpoints.down("sm"));

  const [email, setEmail] = useState("");
  const [mobileNo, setMobileNo] = useState("");
  const [name, setName] = useState("");

  const [validation, setValidation] = useState({
    name: true,
    email: true,
    mobile: true,
  });

  const [showmessage, setShowmessage] = useState(false);

  return (
    <div
      className={
        isMobileScreen ? "enquiryFormCointainermobile" : "enquiryFormCointainer"
      }
      id="Enquire Now">
      <div className={isMobileScreen ? "" : "halfWidth"}>
        <img alt="Hello" src={'https://firebasestorage.googleapis.com/v0/b/livefitfitnessstudio-2c22c.appspot.com/o/sliding_images_New%2Fsliderimage1.jpg?alt=media&token=d2c5139d-c2fc-49ce-a973-8f217caad446'} width={`100%`} />
      </div>
      <div
        className={
          isMobileScreen ? "formContainer" : "halfWidth formContainer"
        }>
        <form className="form" noValidate autoComplete="off">
          <div className="title">Enquire Now For Special Offers</div>
          <div className="enquiryForm">
            <div className="textbox">
              <ThemeProvider theme={theme}>
                <TextField
                  required
                  fullWidth
                  error={!validation.name}
                  label="Name"
                  variant="outlined"
                  size="small"
                  name="Name"
                  value={name}
                  onChange={(event) => {
                    setName(event.target.value);
                    if(showmessage) {
                      setShowmessage(false);
                    }
                  }}
                />
              </ThemeProvider>
            </div>

            <div className="textbox">
              <ThemeProvider theme={theme}>
                <TextField
                  required
                  error={!validation.mobile}
                  fullWidth
                  label="Mobile"
                  variant="outlined"
                  size="small"
                  name="Mobile"
                  value={mobileNo}
                  onChange={(event) => {
                    setMobileNo(event.target.value);
                    if(showmessage) {
                      setShowmessage(false);
                    }
                  }}
                />
              </ThemeProvider>
            </div>
            <div className="textbox">
              <ThemeProvider theme={theme}>
                <TextField
                  required
                  fullWidth
                  error={!validation.email}
                  label="Email"
                  variant="outlined"
                  size="small"
                  name="Email"
                  value={email}
                  onChange={(event) => {
                    setEmail(event.target.value);
                    if(showmessage) {
                      setShowmessage(false);
                    }
                  }}
                />
              </ThemeProvider>
            </div>
            {showmessage ? <div className="successmessage">Submitted Successfully</div> : ''}
            <div className="submit">
              <ColorButton
                onClick={() => {
                  setValidation((prevState) => {
                    return {
                      name: isValidName(name),
                      email: isValidEmailId(email),
                      mobile: isValidMobileNumber(mobileNo),
                    };
                  });
                  Submit(
                    isValidName(name),
                    isValidEmailId(email),
                    isValidMobileNumber(mobileNo),
                    setEmail,
                    setMobileNo,
                    setName,
                    setShowmessage
                  );
                }}
                fullWidth
                variant="contained">
                Submit
              </ColorButton>
            </div>
          </div>
        </form>
      </div>
    </div>
  );
};

export default EnquiryForm;
