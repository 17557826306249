
const emailRegex = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

export const isValidMobileNumber = (value) => {
    return /^[6-9]\d{9}$/.test(value);
}

export const isValidEmailId = (value) => {
    return emailRegex.test(value);
}

export const isValidName = (value) => {
    return /^[a-zA-Z ]*$/.test(value);
}

export const isValidAge = (value) => {
    return /^[1-9]\d{1}$/.test(value);
}