import {initializeApp} from "firebase/app";
import {getAuth} from "firebase/auth";
import {getFirestore} from "firebase/firestore";
import {getStorage} from "firebase/storage";

const app = initializeApp({
  apiKey: "AIzaSyAptVg44TueHLbIKqxTwLDFxuhO6I58Dak",
  authDomain: "livefitfitnessstudio-2c22c.firebaseapp.com",
  databaseURL: "https://livefitfitnessstudio-2c22c-default-rtdb.asia-southeast1.firebasedatabase.app",
  projectId: "livefitfitnessstudio-2c22c",
  storageBucket: "livefitfitnessstudio-2c22c.appspot.com",
  messagingSenderId: "296579545313",
  appId: "1:296579545313:web:21bce7aae861ef71999fa0",
  measurementId: "G-22CXDE0VWQ"
});

const db = getFirestore(app);
const auth = getAuth(app);
const storage = getStorage(app);

export { db, auth, storage };