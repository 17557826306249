
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import Sliders from './Sliders';

import './slick.css';

function Slick(props) {

  const theme = useTheme();
  let isMobileScreen = useMediaQuery(theme.breakpoints.down('sm'));

    return (
      <div className="slickcontainer" id='Classes'>
        <Sliders 
          isMobileScreen = {isMobileScreen}
        />
      </div>
    )
}

export default Slick;