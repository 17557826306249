import React from 'react'
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import "./Banner.css"
import { CardMedia } from '@mui/material';
import { Link } from 'react-scroll';

function Banner(props) {

  const theme = useTheme();
  let isMobileScreen = useMediaQuery(theme.breakpoints.down('sm'));

  let isMD = useMediaQuery(theme.breakpoints.down('md'));

  return (
    <header
      className= {isMD? isMobileScreen ? 'banner halfheight' : 'banner seventyfiveheight' : 'banner fullheight'}
      id='banner'
    >
      <CardMedia
        component="video"
        className="vdo"
        src="https://firebasestorage.googleapis.com/v0/b/livefitfitnessstudio-2c22c.appspot.com/o/HomeVideo_WithoutSound_Latest.mp4?alt=media&token=f116fe7e-1b9c-43fd-92b6-d039e8e0d492"
        autoPlay
        loop
        muted
        playsInline
        autobuffer 
        
      />


      {/* <video width={`100%`} height={`100%`} className="vdo" autoplay loop muted playsInline>
      <source src="https://firebasestorage.googleapis.com/v0/b/livefitfitnessstudio-2c22c.appspot.com/o/HomeVideo_WithoutSound_New.mp4?alt=media&token=eb91af7a-344f-4f0c-8aba-14b5cedd2a95" type="video/mp4" />
      
     </video> */}
     {/* <video width={`100%`} height={`100%`} className="vdo"  autoPlay loop muted playsInline>
      <source src="https://firebasestorage.googleapis.com/v0/b/livefitfitnessstudio-2c22c.appspot.com/o/Banner_Video.mp4?alt=media&token=5f0e745a-f975-4d21-b134-0701cf8cce96" type="video/mp4" />
     </video> */}
      <div 
        className={isMobileScreen? 'btn_join halfheight' : 'btn_join fullheight'}
      >
        {/* <Link to="Enquire Now" spy={true} smooth={true} offset={-50} duration={500} style={{flexGrow:1}}> */}
        <Link className='btndiv' to="Enquire Now" spy={true} smooth={true} offset={-50} duration={500} style={{flexGrow:1}}>
        {/* <div className='btndiv'> */}
          <button className={isMobileScreen? 'banner__button mobile_banner__button' : 'banner__button web_banner__button'} >Join Now</button>
        {/* </div> */}
        </Link>
      </div>
    </header>
  )
}

export default Banner