export function Submit(
  name,
  email,
  mobile,
  setEmail,
  setMobileNo,
  setName,
  setShowmessage,
  setloader
) {
  if (name && email && mobile) {
    console.log(name + " " + email + " " + mobile);
    const formEle = document.querySelector("form");
    const formDatab = new FormData(formEle);

    console.log(formDatab);
    // setloader(true);
    postData(formDatab);
    setShowmessage(true);
    setEmail("");
    setMobileNo("");
    setName("");
    // setloader(false);
  }
}

const postData = async (formDatab) => {
  const res = await fetch(
    "https://script.google.com/macros/s/AKfycbyx32gMVet5QzPq_ib3_rEGyv19MP9BzXYrg4bS4b07ZyBJso5BAds61hneLe9Msbo9-Q/exec",
    {
      method: "POST",
      body: formDatab,
    }
  ).catch((error) => {
    console.log(error);
  });

  const data = await res.json();
  // try {
  //   console.log(data.data.msg);
  //   // alert(data.data.msg);
  // } catch (err) {
  //   console.log(err);
  // }
};
