import React, { useEffect, useState } from "react";
import { useStateValue } from "./StateProvider";
import Header from "../Header";
import Footer from "../Footer";
import "../Home.css";
import { db } from "./firebase";
import {
  query,
  getDocs,
  collection,
  onSnapshot,
  orderBy,
} from "firebase/firestore";
import { useNavigate } from "react-router-dom";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import IconButton from "@mui/material/IconButton";
import DeleteIcon from "@mui/icons-material/Delete";
import EditIcon from "@mui/icons-material/Edit";
import QrCodeIcon from "@mui/icons-material/QrCode";
import CalendarMonthIcon from "@mui/icons-material/CalendarMonth";
import FormDialog from "./FormDialog";
import { onAuthStateChanged } from "firebase/auth";
import { auth } from "./firebase";
import { TablePagination } from "@mui/material";
import Popup from "../components/Popup";
import VisibilityIcon from "@mui/icons-material/Visibility";

import dayjs, { Dayjs } from 'dayjs';
import isBetweenPlugin from 'dayjs/plugin/isBetween';
import { styled } from '@mui/material/styles';
import { PickersDay, PickersDayProps, pickersDayClasses } from '@mui/x-date-pickers/PickersDay';
import TextField from '@mui/material/TextField';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { StaticDatePicker } from '@mui/x-date-pickers/StaticDatePicker';


dayjs.extend(isBetweenPlugin);

// interface CustomPickerDayProps extends PickersDayProps<Dayjs> {
//   dayIsBetween: boolean;
//   isFirstDay: boolean;
//   isLastDay: boolean;
// }

const CustomPickersDay = styled(PickersDay, {
  shouldForwardProp: (prop) =>
    prop !== 'isFirstDay',
})(({ theme, isFirstDay }) => ({
  // ...(dayIsBetween && {
  //   // borderRadius: 0,
  //   borderTopLeftRadius: '50%',
  //   borderBottomLeftRadius: '50%',
  //   borderTopRightRadius: '50%',
  //   borderBottomRightRadius: '50%',
  //   // backgroundColor: theme.palette.primary.main,
  //   backgroundColor: '#ABF36C',
  //   color: theme.palette.common.white,
  //   '&:hover, &:focus': {
  //     // backgroundColor: theme.palette.primary.dark,
  //     backgroundColor: '#82B655',
  //   },
  // }),
  ...(isFirstDay && {
    borderTopLeftRadius: '50%',
    borderBottomLeftRadius: '50%',
    borderTopRightRadius: '50%',
    borderBottomRightRadius: '50%',
    backgroundColor: '#ABF36C',
    color: theme.palette.common.white,
    '&:hover, &:focus': {
      // backgroundColor: theme.palette.primary.dark,
      backgroundColor: '#82B655',
    },
  }),
  // ...(isLastDay && {
  //   borderTopLeftRadius: '50%',
  //   borderBottomLeftRadius: '50%',
  //   borderTopRightRadius: '50%',
  //   borderBottomRightRadius: '50%',
  // }),
}));


const pages = ["log"];
function Users() {
  const [{ admin, open, users }, dispatch] = useStateValue();
  const [curUsers, setCurUsers] = useState({
    allUsers: [],
    filteredUsers: [],
  });
  const navigate = useNavigate();
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(5);

  const [pagenatedUser, setPagenatedUser] = useState([]);

  const [searchText, setSearchText] = useState("");

  const [disableAddUpdate, setDisableAddUpdate] = useState(false);

  const [popUp, setPopup] = useState({
    showPopUp: false,
    title: "",
    message: "",
  });

  const [attendance, setAttendance] = useState({
    showPopUp: false,
    userName: "",
  });

  const [logData, setLogData] = useState([]);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);

    setPagenatedUser((prevState) => {
      return curUsers?.filteredUsers?.filter((value, index) => {
        if (
          newPage * rowsPerPage <= index &&
          (newPage + 1) * rowsPerPage > index
        ) {
          return true;
        } else {
          return false;
        }
      });
    });
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
    setPagenatedUser((prevState) => {
      return curUsers?.filteredUsers?.filter((value, index) => {
        return index < event.target.value;
      });
    });
  };

  useEffect(() => {
    onAuthStateChanged(auth, (data) => {
      console.log("On authstate changed");
      if (data) {
        console.log("logged in");
        console.log(data);
        dispatch({
          type: "SET_ADMIN",
          admin: data,
        });
        console.log(auth);
        navigate("/users");
      } else {
        console.log("Not logged in");
        navigate("/login");
      }
    });
  }, []);

  useEffect(() => {
    console.log("Admin effect");
    console.log(admin);
    // commenting the login validation
    // if (admin === null) {
    //   navigate("/login");
    // }
  }, [admin]);

  // useEffect(() => {
  //   console.log("Users fetch");
  //   const q = query(collection(db, "users"));
  //   getDocs(q)
  //     .then(resp => {
  //       console.log(resp.docs);
  //       const usr = resp.docs.map(doc => ({
  //         data : doc.data(),
  //         id : doc.id
  //       }));
  //       setUsers(usr);
  //     }).catch(e => alert(e.message));

  // }, [])

  useEffect(() => {
    // setPagenatedUser(() => {

    // });

    // setPage(0);

    // setPagenatedUser((prevState) => {
    //   return users.filter((value, index) => {

    //     if(((page * rowsPerPage) <= index) && (((page + 1) * rowsPerPage) > index) ) {
    //       return true;
    //     } else {
    //       return false;
    //     }
    //   });
    // })

    const filteredValue = curUsers?.allUsers?.filter((value, index) => {
      return value.data.name
        ?.toLowerCase()
        .includes(searchText?.toLocaleLowerCase());
    });

    setCurUsers((prevState) => {
      return {
        ...prevState,
        filteredUsers: filteredValue,
      };
    });

    setPage(0);

    setPagenatedUser((prevState) => {
      return filteredValue?.filter((value, index) => {
        return index < rowsPerPage;
      });
    });
  }, [searchText]);

  useEffect(() => {
    console.log("On snapshot - users");
    //const userRef = collection(db, "users");
    const q = query(collection(db, "users"), orderBy("renewalDate"));
    const unsubscribe = onSnapshot(q, (snapShot) => {
      const usr = snapShot.docs.map((doc) => ({
        data: doc.data(),
        id: doc.id,
      }));

      setCurUsers((prevState) => {
        return {
          allUsers: usr,
          filteredUsers: usr,
        };
      });

      setPagenatedUser((prevState) => {
        return usr.filter((value, index) => {
          return index < rowsPerPage;
        });
      });
      dispatch({
        type: "SET_USERS",
        users: usr,
      });
    });

    return () => {
      unsubscribe();
    };
  }, []);


  // const [value, setValue] = React.useState(dayjs('2022-04-07'));

  const [value, setValue] = React.useState([dayjs('2022-04-07'), dayjs('2023-04-09')]);

  const renderWeekPickerDay = (
    date,
    selectedDates,
    pickersDayProps,
  ) => {
    if (!logData) {
      return <PickersDay {...pickersDayProps} />;
    }

    // const start = value.startOf('week');
    // const end = value.endOf('week');

    // const dayIsBetween = date.isBetween(start, end, null, '[]');
    // const isFirstDay = date.isSame(start, 'day');
    // const isLastDay = date.isSame(end, 'day');

    const index = logData?.findIndex((element) => {
      // const da = new Date(1970, 0, 1);
      // da.setSeconds(element?.entry?.seconds);
      return date.isSame(dayjs(element?.entry?.toDate()), 'day');
    });

    let isFirstDay = false;
    if(index > -1) {
      isFirstDay = true;
    }

    return (
      <CustomPickersDay
        {...pickersDayProps}
        sx={{
          [`&&.${pickersDayClasses.selected}`]: {
            backgroundColor: '#ABF36C'
          }
        }}
        disableMargin
        // dayIsBetween={dayIsBetween}
        isFirstDay={isFirstDay}
        // isLastDay={isLastDay}
      />
    );
  };

  function handleEdit(id) {
    console.log("Current Id :" + id);
    setDisableAddUpdate(false); 
    dispatch({
      type: "SET_OPEN",
      openEdit: true,
    });
    dispatch({
      type: "SET_CURRENT_USER_ID",
      currentId: id,
    });
  }

  function handleDelete(id) {
    console.log("Users :: handleDelete ::");
    console.log("Current Id :" + id);
    dispatch({
      type: "SET_OPEN_DELETE",
      openDelete: true,
    });
    dispatch({
      type: "SET_CURRENT_USER_ID",
      currentId: id,
    });
  }

  function handleView(id) {
    console.log("Current Id :" + id);
    dispatch({
      type: "SET_OPEN_VIEW",
      openView: true,
    });
    dispatch({
      type: "SET_CURRENT_USER_ID",
      currentId: id,
    });
  }

  function handleLog(id) {
    console.log("Current Id :" + id);
    console.log(users);

    // check whether context has this id & log
    let currentUser = users?.find(u => u.id === id);
    console.log("Current User ", currentUser);
    const isLogDataInAppContext = currentUser?.log!== undefined;
    console.log("Log data in the app context :", isLogDataInAppContext );

    setAttendance((prevState) => {
      return {
        showPopUp: true,
        userName: currentUser?.data?.name,
      }
    })

    if(isLogDataInAppContext){
      setLogData(currentUser.log);
      return;
    }

    // if not in the context users, then check for DB.

    const logRef = collection(db, "users", id, "log");

    getDocs(logRef)
      .then((data) => {
        console.log("Success");
        setLogData(data.docs.map(d => ({id: d.id, ...d.data()})));
        if (logData?.length !== 0) {
          console.log(logData);
          currentUser.log = logData;
        }
        if(currentUser?.log !== undefined) {
          console.log("Setting in app context user : ", users);
          dispatch({
            type: "SET_USERS",
            users: users,
          });
        }
      }).catch((e) => {
        console.log(e);
      })
      
      
    dispatch({
      type: "SET_CURRENT_USER_ID",
      currentId: id,
    });

  }

  useEffect(() => {
      // Calendar setting function goes here
      console.log("UseEffect calendar settings functionn goes here...! ", logData)
  }, [logData])

  const setPopUpData = (open, title, message) => {
    setPopup({
      showPopUp: open,
      title: title,
      message: message,
    });
  };

  return (
    <div className="home">
      <Header pages={pages} user={true} externalLink = {true} positionExternalLinkRight = {true}/>
      <div style={{ marginTop: "80px" }}>
        {" "}
        <br></br>
      </div>
      {/* <Footer/> */}

      <FormDialog
        setPopUpData={setPopUpData}
        searchText={searchText}
        setSearchText={setSearchText}
        disableAddUpdate = {disableAddUpdate}
        setDisableAddUpdate = {setDisableAddUpdate}
      />
      <Popup
        open={popUp.showPopUp}
        title={popUp.title}
        message={popUp.message}
        handleClose={() => {
          setPopup({
            showPopUp: false,
            title: "",
            message: "",
          });
        }}
      />

      <Popup
        open={attendance.showPopUp}
        title="Log Entry"
        // message={popUp.message}
        handleClose={() => {
          setAttendance({
            showPopUp: false,
            userName: "",
          });
        }}
      >
        <h2 className="attendanceheader">Attendance for {attendance.userName}</h2>
          <LocalizationProvider dateAdapter={AdapterDayjs}>
            <StaticDatePicker
              displayStaticWrapperAs="desktop"
              disableHighlightToday
              readOnly
              label="Week picker"
              value={value}
              // onChange={(newValue) => {
              //   setValue(newValue);
              // }}
              renderDay={renderWeekPickerDay}
              renderInput={(params) => <TextField {...params} />}
              inputFormat="'Week of' MMM d"
            />
          </LocalizationProvider>
      </Popup>

      <div className="tablestyle">
        {/* <TableContainer component={Paper} sx={{margin:2}}> */}
        <TableContainer component={Paper}>
          <Table sx={{ minWidth: 650 }} aria-label="simple table" size="small">
            <TableHead sx={{ bgcolor: "#f5f5f5" }}>
              <TableRow>
                <TableCell sx={{ fontWeight: 700 }}>Users</TableCell>
                <TableCell sx={{ fontWeight: 700 }} align="right">
                  View
                </TableCell>
                <TableCell sx={{ fontWeight: 700 }} align="right">
                  Update
                </TableCell>
                <TableCell sx={{ fontWeight: 700 }} align="right">
                  Delete
                </TableCell>
                <TableCell sx={{ fontWeight: 700 }} align="right">
                  Log Entry
                </TableCell>
                {/* <TableCell sx={{ fontWeight: 700 }} align="right">
                  QR Code{" "}
                </TableCell> */}
              </TableRow>
            </TableHead>
            <TableBody>
              {pagenatedUser?.map((row) => (
                <TableRow key={row.id} sx={{ height: 10 }}>
                  <TableCell component="th" scope="row">
                    {row.data.name}
                  </TableCell>

                  <TableCell align="right">
                    <IconButton
                      aria-label="edit"
                      size="small"
                      onClick={() => handleView(row.id)}
                    >
                      <VisibilityIcon />
                    </IconButton>
                  </TableCell>

                  <TableCell align="right">
                    <IconButton
                      aria-label="edit"
                      size="small"
                      onClick={() => handleEdit(row.id)}
                    >
                      <EditIcon />
                    </IconButton>
                  </TableCell>
                  <TableCell align="right">
                    <IconButton
                      aria-label="delete"
                      size="small"
                      onClick={() => handleDelete(row.id)}
                    >
                      <DeleteIcon />
                    </IconButton>
                  </TableCell>
                  <TableCell align="right">
                    <IconButton 
                      aria-label="calendarMonth" 
                      size="small"
                      onClick={() => handleLog(row.id)}
                    >
                      <CalendarMonthIcon />
                    </IconButton>
                  </TableCell>
                  {/* <TableCell align="right">
                    <IconButton aria-label="qr" size="small">
                      <QrCodeIcon />
                    </IconButton>
                  </TableCell> */}
                </TableRow>
              ))}
            </TableBody>
          </Table>
          <TablePagination
            rowsPerPageOptions={[5, 10, 25, 100]}
            component="div"
            count={curUsers?.filteredUsers?.length}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />
        </TableContainer>
      </div>
    </div>
  );
}

export default Users;
