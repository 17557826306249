import React from "react";
import "./Contact.css";

function Contact() {
  return (
    <div id="Visit Us">
      <div className="container my-24 px-6 mx-auto">
        <section className="mb-32 text-gray-800">
          <div className="block rounded-lg border-slate-200 shadow-lg">
            <div className="flex flex-wrap flex-start">
              <div className="grow-0 p-3 rounded-lg shrink-0 basis-auto block w-full items-center lg:flex lg:w-6/12 xl:w-4/12">
                <div className="map-container-2 w-full">
                  <iframe
                    title="LiveFit Fitness Studio"
                    src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d243.05220008725772!2d80.22985775615527!3d12.918311348870436!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3a525d3fc6eec6ff%3A0xb097d8510034cbe1!2sLiveFit%20Fitness%20Studio!5e0!3m2!1sen!2sus!4v1672870773854!5m2!1sen!2sus"
                    className="left-0 top-0 h-full w-full rounded-t-lg lg:rounded-tr-none lg:rounded-bl-lg"
                    frameBorder="0"
                    allowFullScreen></iframe>
                </div>
              </div>

              <div className="grow-0 shrink-0 basis-auto w-full lg:w-6/12 xl:w-8/12 flex flex-col p-5">
                <div className="container my-2 px-6 mx-auto p-8">
                  <section className="mb-3 text-gray-800 text-center">
                    <div className="flex justify-center">
                      <div className="max-w-[800px]">
                        <h2 className="text-5xl md:text-6xl xl:text-5xl font-bold tracking-tight mb-2">
                          <span className="text-red-600">Visit us </span>
                          Today
                        </h2>
                      </div>
                    </div>
                  </section>
                </div>
                <div className="flex flex-wrap pt-3 lg:pt-0 flex-">
                  <div className="mb-12 grow-0 shrink-0 basis-auto w-full md:w-6/12 lg:w-full xl:w-6/12 px-3 md:px-6 xl:px-12">
                    <div className="flex items-start">
                      <div className="shrink-0">
                        <div className="p-4 bg-red-600 rounded-md shadow-md w-14 h-14 flex items-center justify-center">
                          <svg
                            aria-hidden="true"
                            focusable="false"
                            data-prefix="fas"
                            data-icon="newspaper"
                            className="w-5 text-white"
                            role="img"
                            xmlns="http://www.w3.org/2000/svg"
                            viewBox="0 0 576 512">
                            <path
                              fill="currentColor"
                              d="M552 64H88c-13.255 0-24 10.745-24 24v8H24c-13.255 0-24 10.745-24 24v272c0 30.928 25.072 56 56 56h472c26.51 0 48-21.49 48-48V88c0-13.255-10.745-24-24-24zM56 400a8 8 0 0 1-8-8V144h16v248a8 8 0 0 1-8 8zm236-16H140c-6.627 0-12-5.373-12-12v-8c0-6.627 5.373-12 12-12h152c6.627 0 12 5.373 12 12v8c0 6.627-5.373 12-12 12zm208 0H348c-6.627 0-12-5.373-12-12v-8c0-6.627 5.373-12 12-12h152c6.627 0 12 5.373 12 12v8c0 6.627-5.373 12-12 12zm-208-96H140c-6.627 0-12-5.373-12-12v-8c0-6.627 5.373-12 12-12h152c6.627 0 12 5.373 12 12v8c0 6.627-5.373 12-12 12zm208 0H348c-6.627 0-12-5.373-12-12v-8c0-6.627 5.373-12 12-12h152c6.627 0 12 5.373 12 12v8c0 6.627-5.373 12-12 12zm0-96H140c-6.627 0-12-5.373-12-12v-40c0-6.627 5.373-12 12-12h360c6.627 0 12 5.373 12 12v40c0 6.627-5.373 12-12 12z"></path>
                          </svg>
                        </div>
                      </div>
                      <div className="grow ml-6">
                        <p className="font-bold mb-1">Address</p>
                        <p className="text-gray-500">LiveFit Fitness Studio,</p>
                        <p className="text-gray-500">Door No.41A,</p>
                        <p className="text-gray-500">OMR Road,</p>
                        <p className="text-gray-500">
                          Rajiv Gandhi Salai, Karapakkam,
                        </p>
                        <p className="text-gray-500">Chennai,</p>
                        <p className="text-gray-500">Tamil Nadu - 600097.</p>
                      </div>
                    </div>
                  </div>

                  <div className="mb-12 grow-0 shrink-0 basis-auto w-full md:w-6/12 lg:w-full xl:w-6/12 px-3 md:px-6 xl:px-12">
                    <div className="flex items-start">
                      <div className="shrink-0">
                        <div className="p-4 bg-red-600 rounded-md shadow-md w-14 h-14 flex items-center justify-center">
                          <svg
                            aria-hidden="true"
                            focusable="false"
                            data-prefix="fas"
                            data-icon="headset"
                            className="w-5 text-white"
                            role="img"
                            xmlns="http://www.w3.org/2000/svg"
                            viewBox="0 0 512 512">
                            <path
                              fill="currentColor"
                              d="M192 208c0-17.67-14.33-32-32-32h-16c-35.35 0-64 28.65-64 64v48c0 35.35 28.65 64 64 64h16c17.67 0 32-14.33 32-32V208zm176 144c35.35 0 64-28.65 64-64v-48c0-35.35-28.65-64-64-64h-16c-17.67 0-32 14.33-32 32v112c0 17.67 14.33 32 32 32h16zM256 0C113.18 0 4.58 118.83 0 256v16c0 8.84 7.16 16 16 16h16c8.84 0 16-7.16 16-16v-16c0-114.69 93.31-208 208-208s208 93.31 208 208h-.12c.08 2.43.12 165.72.12 165.72 0 23.35-18.93 42.28-42.28 42.28H320c0-26.51-21.49-48-48-48h-32c-26.51 0-48 21.49-48 48s21.49 48 48 48h181.72c49.86 0 90.28-40.42 90.28-90.28V256C507.42 118.83 398.82 0 256 0z"></path>
                          </svg>
                        </div>
                      </div>
                      <div className="grow ml-6">
                        <p className="font-bold mb-1">Contacts</p>
                        <p className="text-gray-500">
                          E-mail: Livefitfitnessstudio@gmail.com
                        </p>
                        {/* <p className="text-gray-500">
                          dominicraj.a08@gmail.com
                        </p> */}
                        <br />
                        <p className="text-gray-500">Mobile numbers: </p>
                        {/* <p className="text-gray-500">
                          Dominic: +91 96772 99734
                        </p> */}
                        <p className="text-gray-500">Dilli: +91 98844 80763</p>
                        <p className="text-gray-500">Salin: +91 78457 13138</p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
    </div>
  );
}

export default Contact;
