import React from "react";
import "./Home.css";
import Banner from "./Banner";
import Header from "./Header";
import Slick from "./slick-slider/Slick";
import Footer from "./Footer";
import Pricing from "./components/Pricing/Pricing";
import Contact from "./components/Contact/Contact";
import Explore from "./components/Explore/Explore";
import EnquiryForm from "./components/enquiryform/EnquiryForm";

function Home() {
  const pages = [
    "Classes",
    "General Training",
    "Personal Training",
    "Enquire Now",
    "Visit Us",
  ];

  return (
    <div className="home">
      <Header pages={pages} />
      <Banner />
      <Slick />
      <Pricing />
      <Explore />
      <EnquiryForm />
      <Contact />
      <Footer />
    </div>
  );
}

export default Home;
