import React, { useState, useEffect } from "react";
import OutlinedInput from "@mui/material/OutlinedInput";
import InputAdornment from "@mui/material/InputAdornment";
import SearchIcon from "@mui/icons-material/Search";
import Button from "@mui/material/Button";
import { Card, CardContent } from "@mui/material";
import { DataGrid } from "@mui/x-data-grid";
import Header from "../Header";
// import { db } from "./firebase";
import { db } from "../admin/firebase";
import {
  query,
  getDocs,
  collection,
  onSnapshot,
  orderBy,
  addDoc,
  serverTimestamp,
} from "firebase/firestore";

import Popup from "../components/Popup";

import "./log.css";
import { useStateValue } from "../admin/StateProvider";
import { ConstructionOutlined } from "@mui/icons-material";

const pages = ["Users"];

const InputAdornmentComp = (
  <InputAdornment position="start">
    <SearchIcon className="searchIcon" />
  </InputAdornment>
);

const columnValue = [
  { field: "id", headerName: "MobileNo", width: 200 },
  { field: "name", headerName: "Name", width: 200 },
];

const Log = (props) => {
  const [{ users }] = useStateValue();
  const [searchMobileNo, setSearchMobileNo] = useState("");
  const [currentUserLog, setCurrentUserLog] = useState("");
  const [userList, setUserList] = useState({
    allUsers: [],
    filteredUsers: [],
  });

  const [selectedId, setSelectedId] = useState("");
  const [dbId, setDbId] = useState("");

  const [popUp, setPopup] = useState({
    showPopUp: false,
    title: "",
    message: "",
  });

  useEffect(() => {
    console.log("On Get - users");
    //const userRef = collection(db, "users");

    const q = query(collection(db, "users"), orderBy("renewalDate"));
    getDocs(q).then((resp) => {
      const usr = resp.docs.map((doc) => ({
        data: doc.data(),
        id: doc.id,
      }));
      setUserList((prevState) => {
        let filterValue = usr.map((value) => {
          return {
            id: value?.data?.mobile,
            name: value?.data?.name,
          };
        });
        return {
          allUsers: usr,
          filteredUsers: [],
        };
      });

      //   setPagenatedUser((prevState) => {
      //     return usr.filter((value, index) => {
      //       return index < rowsPerPage;
      //     });
      //   });
      //   dispatch({
      //     type: "SET_USERS",
      //     users: usr,
      //   });
    });

    // return () => {
    //   unsubscribe();
    // };
  }, []);

  useEffect(() => {
    // setPagenatedUser(() => {

    // });

    // setPage(0);

    // setPagenatedUser((prevState) => {
    //   return users.filter((value, index) => {

    //     if(((page * rowsPerPage) <= index) && (((page + 1) * rowsPerPage) > index) ) {
    //       return true;
    //     } else {
    //       return false;
    //     }
    //   });
    // })

    const filteredValue = userList?.allUsers?.filter((value, index) => {
      return (
        value.data.mobile
          ?.toLowerCase()
          .includes(searchMobileNo?.toLocaleLowerCase()) &&
        searchMobileNo.length > 9
      );
    });

    if (
      filteredValue?.filter((value) => {
        return value?.data?.mobile === selectedId;
      })?.length === 0
    ) {
      setSelectedId("");
    }

    setUserList((prevState) => {
      let filterValue = filteredValue.map((value) => {
        return {
          id: value?.data?.mobile,
          name: value?.data?.name,
          dbId: value?.id,
        };
      });
      return {
        ...prevState,
        filteredUsers: filterValue,
      };
    });

    // setPage(0);

    // setPagenatedUser((prevState) => {
    //   return filteredValue?.filter((value, index) => {
    //     return index < rowsPerPage;
    //   });
    // });
  }, [searchMobileNo]);

  const handleClickOpen = () => {
    console.log("Clickedddd");
    let planRenewalDate;
    userList.allUsers.forEach((usr) => {
      if (usr?.data?.mobile === selectedId) {
        // planRenewalDate = new Date(1970, 0, 1);
        // planRenewalDate.setSeconds(usr?.data?.renewalDate?.seconds);
        planRenewalDate = usr?.data?.renewalDate?.toDate();
        planRenewalDate.setHours(0,0,0,0);
      }
    });
    const today = new Date();
    today.setHours(0,0,0,0);
    if (planRenewalDate) {
      if (planRenewalDate >= today) {
        // const data = {
        //   entry: serverTimestamp(),
        // };

        if (dbId === undefined || dbId === "") {
          console.log("DB ID undefinde : ", dbId);
          return;
        }

        // getting log data from firestore and validate already existing data
        const logRef = collection(db, "users", dbId, "log");

        let loggingData = [];

         getDocs(logRef)
          .then((data) => {
            console.log("Success");
            setCurrentUserLog(data.docs.map(d => ({id: d.id, ...d.data()})))
            loggingData = data.docs.map(d => ({id: d.id, ...d.data()}));

            let alreadyLogged = false;

            if(loggingData?.length >=0){
              loggingData.forEach((log) => {
                // let logDate = new Date(1970, 0, 1);
                // logDate.setSeconds(log?.entry?.seconds);
                if(log?.entry?.toDate()?.toDateString() === new Date()?.toDateString()) {
                  alreadyLogged = true;
                }
              })
    
              if(!alreadyLogged) {

                const todayDate = {
                  entry: serverTimestamp(),
                };
    
                const userRef = collection(db, "users", dbId, "log");
                console.log(userRef);
    
                addDoc(userRef, todayDate)
                  .then((e) => {
                    setPopup({
                      showPopUp: true,
                      title: "Success",
                      message: "Attendance Logged Successfully !!!",
                    });
                    console.log("Logged successfully for Today");
                    console.log(e);
                  })
                  .catch((e) => {
                    setPopup({
                      showPopUp: true,
                      title: "Failed",
                      message: "Error on logging Retry !!!",
                    });
                    console.log("Error on logging");
                    console.log(e);
                  });
    
              } else {
                setPopup({
                  showPopUp: true,
                  title: "Attendance Logged",
                  message: "Already Attendance Logged for Today !!!",
                });
              }
    
            } else {
              setPopup({
                showPopUp: true,
                title: "Failed",
                message: "Something went wrong !!!",
              });    
            }
          })
          .catch((e) => {
            console.log(e);
            setPopup({
              showPopUp: true,
              title: "Failed",
              message: "Something went wrong !!!",
            }); 
          });
        
      } else {
        setPopup({
          showPopUp: true,
          title: "Renew Plan",
          message: "Please Renew the Expired Plan !!!",
        });
      }
    } else {
      setPopup({
        showPopUp: true,
        title: "Failed",
        message: "Something went wrong !!!",
      });
    }
  };

  // Effect on dbID
  useEffect(() => {
    console.log("Selected dbId ", dbId);
    console.log("Current user log ... : ", currentUserLog)
  }, [dbId, currentUserLog]);

  return (
    <div className="panel">
      <Popup
        open={popUp.showPopUp}
        title={popUp.title}
        message={popUp.message}
        handleClose={() => {
          setPopup({
            showPopUp: false,
            title: "",
            message: "",
          });
        }}
      />
      <Header
        pages={pages}
        user={true}
        externalLink={true}
        positionExternalLinkRight={true}
      />
      <div style={{ marginTop: "80px" }}>
        {" "}
        <br></br>
      </div>
      <Card sx={{ boxShadow: 3 }}>
        <CardContent className="login_content">
          <h2 className="header">LOG ENTRY</h2>
          <OutlinedInput
            id="outlined"
            autoComplete="off"
            className="inputstyle"
            placeholder="Search By Mobile No"
            value={searchMobileNo}
            startAdornment={InputAdornmentComp}
            onChange={(e) => {
              setSearchMobileNo((prevState) => {
                return e.target.value;
              });
            }}
          />

          {userList.filteredUsers.length != 0 ? (
            <div className="datatable">
              <DataGrid
                rows={userList.filteredUsers}
                columns={columnValue}
                hideFooter
                autoHeight
                onRowClick={(params) => {
                  setSelectedId((prevState) => {
                    return params.id;
                  });
                  setDbId(params.row.dbId);

                  // const logRef = collection(db, "users", params?.row?.dbId, "log");

                  // getDocs(logRef)
                  //   .then((data) => {
                  //     console.log("Success");
                  //     setCurrentUserLog(data.docs.map(d => ({id: d.id, ...d.data()})))
                  //     console.log(data.docs.map(d => ({id: d.id, ...d.data()})))
                  //   })
                  //   .catch((e) => {
                  //     console.log(e);
                  //   });
                }}
                columnHeaderHeight={0}
              />
            </div>
          ) : (
            ""
          )}

          <div>
            <Button
              disabled={!selectedId}
              variant="contained"
              sx={{ width: 115, marginTop: 3 }}
              onClick={handleClickOpen}
            >
              Attendance
            </Button>
          </div>
        </CardContent>
      </Card>
      {/* <div className="submitButton">
        <Button
          disabled={!selectedId}
          variant="contained"
          sx={{ width: 115, marginLeft: 2 }}
          onClick={handleClickOpen}
        >
          Attendance
        </Button>
      </div> */}
    </div>
    // </div>
  );
};

export default Log;
