import React from 'react'
import {
    Box,
    chakra,
    Container,
    Stack,
    Text,
    useColorModeValue,
    VisuallyHidden, Image
  } from '@chakra-ui/react';
  import { FaInstagram, FaYoutube } from 'react-icons/fa';
  import { ChakraProvider } from '@chakra-ui/react'
  import { Link } from 'react-scroll';

  const SocialButton = (props) => {
    return (
      <chakra.button
        bg={useColorModeValue('blackAlpha.100', 'whiteAlpha.100')}
        rounded={'full'}
        w={8}
        h={8}
        cursor={'pointer'}
        as={'a'}
        href={props.href}
        display={'inline-flex'}
        alignItems={'center'}
        justifyContent={'center'}
        transition={'background 0.3s ease'}
        _hover={{
          bg: useColorModeValue('blackAlpha.200', 'whiteAlpha.200'),
        }}>
        <VisuallyHidden>{props.label}</VisuallyHidden>
        {props.children}
      </chakra.button>
    );
  };

function Footer() {
  return (
    <ChakraProvider>
    <Box
      bg='black'
      color='white'>
      <Container
        as={Stack}
        maxW={'6xl'}
        py={4}
        direction={{ base: 'column', md: 'row' }}
        spacing={4}
        justify={{ base: 'center', md: 'space-between' }}
        align={{ base: 'center', md: 'center' }}>
          <Link to="banner" spy={true} smooth={true} duration={500}>
            <Box sx={{cursor:'pointer'}}>
  <Image  h={10} w={16}
    objectFit='cover'
     src='https://firebasestorage.googleapis.com/v0/b/livefitfitnessstudio-2c22c.appspot.com/o/E4F3F9CB-943A-46D5-A5B5-C4D722E1336B.jpeg?alt=media&token=a806a313-4c5d-409f-b88a-3a02162b893f' alt='Dan Abramov' />
</Box>
</Link>
        <Box component="div" sx={{ display: 'flex', alignItems:'center', flexDirection:'column'}}>
        <Text fontSize='sm'>© 2023 LIVEFITFITNESSSTUDIO. All rights reserved</Text>
        <Text fontSize='sm'>Developed By TechBinos</Text>
        </Box>
        <Stack direction={'row'} spacing={6}>
          <SocialButton label={'YouTube'} href={''}>
            <FaYoutube />
          </SocialButton>
          <SocialButton label={'Instagram'} href={'https://www.instagram.com/livefit_fitness_studio?igsh=azBpNW81YnNvNnJw'}>
            <FaInstagram />
          </SocialButton>
        </Stack>
      </Container>
    </Box>
    </ChakraProvider>
  )
}

export default Footer
