"use client";

import { useState } from "react";
import { motion } from "framer-motion";

import { exploreOptions } from "../../constants/constants";
import { staggerContainer, textVariant } from "../../utils/motion";

import ExploreCard from "./ExploreCard";

const Explore = () => {
  const [active, setActive] = useState("world-2");

  return (
    <div className="relative" id="Personal Training">
      <div className="">
        <section className={"sm:p-16 xs:p-8 px-6 py-12 "} id="explore">
          <motion.div
            variants={staggerContainer}
            initial="hidden"
            whileInView="show"
            viewport={{ once: false, amount: 0.25 }}
            className={"2xl:max-w-[1280px] w-full mx-auto flex flex-col"}>
            {/* <TypingText title="| The World" textStyles="text-center" /> */}
            {/* <TitleText
          title={
            <>
              Choose the world you want <br className="md:block hidden" /> to
              explore
            </>
          }
          textStyles="text-center"
        /> */}

            <motion.h2
              variants={textVariant}
              initial="hidden"
              whileInView="show"
              // className={
              //   "mt-[8px] font-bold md:text-[64px] text-[40px] text-slate-700 text-center"
              // }
            >
              {/* <>
                Personal Training <br className="md:block hidden" /> to fastrack
                Your <span class="text-red-600"> Fitness </span>
              </> */}
              <div className="container my-5 px-6 mx-auto">
                <section className="mb-3 text-gray-800 text-center">
                  <div className="flex justify-center">
                    <div className="max-w-[800px]">
                      <h2 className="text-5xl md:text-6xl xl:text-7xl font-bold tracking-tight mb-2 ">
                        Personal Training <br />
                        To Fastrack Your
                        <span className="text-red-600"> Fitness </span>
                      </h2>
                    </div>
                  </div>
                </section>
              </div>
            </motion.h2>
            <div className="mt-[50px] flex lg:flex-row flex-col min-h-[70vh] gap-5">
              {exploreOptions.map((option, index) => (
                <ExploreCard
                  key={option.id}
                  {...option}
                  index={index}
                  active={active}
                  handleClick={setActive}
                />
              ))}
            </div>
          </motion.div>
        </section>
      </div>
    </div>
  );
};

export default Explore;
