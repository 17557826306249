import React, { useEffect, useState } from "react";
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import {
  MenuItem,
  Select,
  InputLabel,
  FormControl,
  Tooltip,
} from "@mui/material";
import { db } from "./firebase";
import {
  addDoc,
  collection,
  serverTimestamp,
  doc,
  updateDoc,
  deleteDoc,
} from "firebase/firestore";
import { useStateValue } from "./StateProvider";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import useMediaQuery from "@mui/material/useMediaQuery";
import { useTheme } from "@mui/material/styles";
import { data } from "autoprefixer";
import { PropaneSharp } from "@mui/icons-material";
import IconButton from "@mui/material/IconButton";

import OutlinedInput from "@mui/material/OutlinedInput";
import InputAdornment from "@mui/material/InputAdornment";
import SearchIcon from "@mui/icons-material/Search";
import RestoreIcon from "@mui/icons-material/Restore";

import {
  isValidMobileNumber,
  isValidAge,
  isValidName,
} from "../utils/validation";

import "./formdialog.css";
import { display } from "@mui/system";

export default function FormDialog(props) {
  const {
    setPopUpData,
    searchText,
    setSearchText,
    disableAddUpdate,
    setDisableAddUpdate,
  } = props;

  const [name, setName] = React.useState("");
  const [age, setAge] = React.useState("");
  const [openD, setOpenD] = React.useState(false);
  const [gender, setGender] = React.useState("");
  const [training, setTraining] = React.useState("");
  const [plan, setPlan] = React.useState("");
  const [mobile, setMobile] = React.useState();
  const [{ openEdit, users, currentId, openDelete, openView }, dispatch] =
    useStateValue();
  const [mode, setMode] = useState(""); // ADD, UPDATE, DELETE
  const [joinDate, setJoinDate] = useState(null);
  const [renewalDate, setRenewalDate] = useState(null);
  // handleChange const renewal date
  const [constRenewD, setConstRenewD] = useState(new Date());
  const [constPlan, setConstPlan] = useState("");
  const [open, setOpen] = React.useState(false);
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down("md"));
  const [deleteUser, setDeleteUser] = useState("");
  const [openV, setOpenV] = React.useState(false);
  // const [searchText, setSearchText] = useState('');

  const [validation, setValidation] = useState({
    name: true,
    age: true,
    mobile: true,
  });

  // On click edit from users screen
  useEffect(() => {
    console.log("FormDialog :: useEffect :: openEdit " + openEdit);

    if (openEdit === true) {
      console.log(openEdit);
      setMode("UPDATE");
      console.log("Mode: " + mode);

      console.log("FormDialog :: Current Id :" + currentId);
      const user = users?.find((usr) => usr.id === currentId);
      console.log("Form Dialog : Edit user");
      console.log(user);
      const data = user?.data;

      setName(data?.name);
      setAge(data?.age);
      setGender(data?.gender);
      setTraining(data?.training);
      setPlan(data?.plan);
      setMobile(data?.mobile);
      if (data?.joinDate !== undefined && data?.renewalDate !== undefined) {
        setJoinDate(data?.joinDate?.toDate());
        setRenewalDate(data?.renewalDate?.toDate());
        // renewal date is based on renewal date on update mode
        setConstRenewD(data?.renewalDate?.toDate());
        setConstPlan(data?.plan);
      }
      setOpenD(openEdit);
    }
  }, [openEdit]);

  useEffect(() => {
    console.log("FormDialog :: useEffect :: openView " + openView);

    if (openView === true) {
      console.log(openView);
      // setMode("UPDATE");
      console.log("View: " + mode);

      console.log("FormDialog :: Current Id :" + currentId);
      const user = users?.find((usr) => usr.id === currentId);
      console.log("Form Dialog : Edit user");
      console.log(user);
      const data = user?.data;

      setName(data?.name);
      setAge(data?.age);
      setGender(
        (data?.gender).charAt(0).toUpperCase() + (data?.gender).slice(1)
      );
      setTraining(
        (data?.training).charAt(0).toUpperCase() + (data?.training).slice(1)
      );
      setPlan((data?.plan).charAt(0).toUpperCase() + (data?.plan).slice(1));
      setMobile(data?.mobile);
      if (data?.joinDate !== undefined && data?.renewalDate !== undefined) {
        const joinDt = new Date(data?.joinDate?.toDate());
        setJoinDate(joinDt.toDateString());
        const renewalDt = new Date(data?.renewalDate?.toDate());
        setRenewalDate(renewalDt.toDateString());
      }
      console.log(data);
      setOpenV(openView);
    }
  }, [openView]);

  const handleClickOpen = () => {
    console.log("FormDialog :: handleClickOpen ::");
    setMode("ADD");
    setName("");
    setAge("");
    setGender("");
    setTraining("");
    setPlan("");
    setMobile("");
    setJoinDate(null);
    setRenewalDate(null);

    setOpenD(true);
    setDisableAddUpdate(false);

    console.log("Mode: " + mode);
  };

  const handleClose = () => {
    setDisableAddUpdate(false);

    setOpenD(false);
    setMode("");
    dispatch({
      type: "SET_OPEN",
      openEdit: false,
    });

    setName("");
    setAge("");
    setGender("");
    setTraining("");
    setPlan("");
    setMobile("");
    setJoinDate(null);
    setRenewalDate(null);

    setValidation({
      name: true,
      age: true,
      mobile: true,
    });
  };

  const handleCloseView = () => {
    setOpenV(false);
    setMode("");
    dispatch({
      type: "SET_OPEN_VIEW",
      openEdit: false,
    });

    setName("");
    setAge("");
    setGender("");
    setTraining("");
    setPlan("");
    setMobile("");
    setJoinDate(null);
    setRenewalDate(null);
  };

  const handleCloseDeleteDialog = () => {
    dispatch({
      type: "SET_OPEN_DELETE",
      openDelete: false,
    });
  };

  const handleChangeGender = (e) => {
    setGender(e.target.value);
    console.log(e.target.value);
  };

  const handleChangeTraining = (e) => {
    setTraining(e.target.value);
    console.log(e.target.value);
  };

  const handleChangePlan = (e) => {
    console.log("FormDialog :: handleChangePlan ");
    const value = e.target.value;
    setPlan(value);
    console.log(value);
    console.log("Join date");
    console.log(joinDate);
    console.log("Renewal Date");
    console.log(renewalDate);

    // let renewD;

    // if (mode === "ADD") {
    //   console.log("Mode :Add");
    //   setConstRenewD(joinDate.$d);
    //   renewD = new Date(constRenewD);
    // } else if (mode === "UPDATE") {
    //   console.log("Mode :Update");
    //   //renewD = new Date(renewalDate);
    //   renewD = new Date(constRenewD);
    // }

    // let currentRenewD = new Date();

    // if (value === "monthly") {
    //   console.log("Monthly==true");
    //   currentRenewD.setMonth(renewD.getMonth() + 1);
    //   setRenewalDate(currentRenewD);
    // } else if (value === "quarterly") {
    //   console.log("Quarterly==true");
    //   currentRenewD.setMonth(renewD.getMonth() + 3);
    //   setRenewalDate(currentRenewD);
    // } else if (value === "halfyearly") {
    //   console.log("Halfyearly==true");
    //   currentRenewD.setMonth(renewD.getMonth() + 6);
    //   setRenewalDate(currentRenewD);
    // } else if (value === "yearly") {
    //   console.log("Yearly==true");
    //   currentRenewD.setMonth(renewD.getMonth() + 12);
    //   setRenewalDate(currentRenewD);
    // }
  };

  const handleSave = () => {
    console.log(name);
    console.log(age);
    console.log(gender);
    console.log(training);
    console.log(plan);
    console.log(mobile);
    const joinDt = new Date(joinDate.$d);
    const renewDt = new Date(renewalDate);

    // need to add validation for the input

    // DB validation check if mobile number exist

    const dup = users?.find((usr) => usr?.data?.mobile === mobile);

    if (dup !== undefined) {
      setPopUpData(true, "Failed", "User exists with this mobile number");
       setDisableAddUpdate(false);
      return;
    }

    const data = {
      name: name,
      age: age,
      gender: gender,
      training: training,
      plan: plan,
      mobile: mobile,
      joinDate: joinDt,
      renewalDate: renewDt,
      created_date: serverTimestamp(),
      updated_date: serverTimestamp(),
    };

    const userRef = collection(db, "users");

    addDoc(userRef, data)
      .then((e) => {
        setOpenD(false);
        // alert("Success");
        setMode("");
        setPopUpData(true, "Success", "Added New Record !!!");
      })
      .catch((e) => {
        // alert(e);
        setMode("");
        setOpenD(false);
        setPopUpData(true, "Failed", "Fail to Add New Record");
      });
  };

  const handleUpdate = () => {
    console.log(name);
    console.log(age);
    console.log(gender);
    console.log(training);
    console.log(plan);
    console.log(mobile);

    // need to add validation for the input

    const joinDt = new Date(joinDate);
    const renewDt = new Date(renewalDate);

    const data = {
      name: name,
      age: age,
      gender: gender,
      training: training,
      plan: plan,
      mobile: mobile,
      joinDate: joinDt,
      renewalDate: renewDt,
      updated_date: serverTimestamp(),
    };

    const userRef = doc(db, "users", currentId);

    updateDoc(userRef, data)
      .then((res) => {
        // alert("Success");
        console.log("handleUpdate ::");
        console.log(res);

        setOpenD(false);
        setMode("");
        dispatch({
          type: "SET_OPEN",
          openEdit: false,
        });

        setPopUpData(true, "Success", "Updated Successfully !!!");
      })
      .catch((e) => {
        // alert(e)
        setOpenD(false);
        setMode("");
        dispatch({
          type: "SET_OPEN",
          openEdit: false,
        });
        setPopUpData(true, "Failed", "Fail to Update");
      });
  };

  const handleDeleteUser = () => {
    console.log("FormDialog :: handleDeleteUser :: currentId :: " + currentId);

    const userRef = doc(db, "users", currentId);

    deleteDoc(userRef)
      .then(() => {
        console.log("User deleted...!");
        // alert("User deleted...!");

        setPopUpData(true, "Success", "User deleted...!");

        dispatch({
          type: "SET_OPEN_DELETE",
          openDelete: false,
        });
      })
      .catch((e) => {
        console.log(e.message);
        // alert(e.message);
        setPopUpData(true, "Failed", "Fail to Delete");

        dispatch({
          type: "SET_OPEN_DELETE",
          openDelete: false,
        });
      });
  };

  const handleClickPlan = (e) => {
    console.log(e);
  };

  const handleResetRenewal = () => {
    setPlan(constPlan);
    setRenewalDate(new Date(constRenewD));
  };

  const InputAdornmentComp = (
    <InputAdornment position="start">
      <SearchIcon className="searchIcon" />
    </InputAdornment>
  );

  const enableUpdateButton = () =>{

    const user = users?.find((usr) => usr.id === currentId);
    const data = user?.data;

    const returnValue = ((name === data?.name) && 
            (age === data?.age) && 
            (gender === data?.gender) &&
            (training === data?.training) &&
            (plan === data?.plan) &&
            (renewalDate?.$d ? (renewalDate?.$d?.toDateString() === data?.renewalDate?.toDate()?.toDateString()) : (renewalDate?.toDateString() === data?.renewalDate?.toDate()?.toDateString())));
    
    return returnValue;
  }

  return (
    <div>
      <div className="filterAdd">
        <OutlinedInput
          autoComplete='off'
          id="outlined"
          className="inputstyle"
          placeholder="Search By User"
          value={searchText}
          startAdornment={InputAdornmentComp}
          onChange={(e) => {
            setSearchText((prevState) => {
              return e.target.value;
            });
          }}
        />

        <Button
          variant="contained"
          sx={{ width: 115, marginLeft: 2 }}
          align="right"
          onClick={handleClickOpen}>
          Add User
        </Button>
      </div>

      <Dialog open={openD} onClose={handleClose}>
        {mode === "ADD" && <DialogTitle>Add User</DialogTitle>}
        {mode === "UPDATE" && <DialogTitle>Update User</DialogTitle>}
        <DialogContent>
          <DialogContentText>
            Please enter the user details to register.
          </DialogContentText>
          <TextField
            autoFocus
            required
            margin="dense"
            id="name"
            label="Name"
            fullWidth
            variant="standard"
            onChange={(e) => setName(e.target.value)}
            value={name}
            error={!validation.name}
          />
          <TextField
            required
            margin="dense"
            id="age"
            label="Age"
            fullWidth
            variant="standard"
            onChange={(e) => setAge(e.target.value)}
            value={age}
            error={!validation.age}
          />
          <FormControl fullWidth sx={{ marginTop: "20px" }}>
            <InputLabel id="gender-label" required>
              Gender
            </InputLabel>
            <Select
              labelId="gender-label"
              id="gender"
              value={gender}
              label="Gender"
              onChange={handleChangeGender}
              // error={!validation.gender}
            >
              <MenuItem value={"male"}>Male</MenuItem>
              <MenuItem value={"female"}>Female</MenuItem>
            </Select>
          </FormControl>

          <FormControl fullWidth sx={{ marginTop: "20px" }}>
            <InputLabel id="training-label" required>
              Training
            </InputLabel>
            <Select
              labelId="training-label"
              id="training"
              value={training}
              label="Training"
              onChange={handleChangeTraining}
              // error={!validation.training}
            >
              <MenuItem value={"personal"}>Personal</MenuItem>
              <MenuItem value={"general"}>General</MenuItem>
            </Select>
          </FormControl>
          <FormControl
            // error={!validation.joinDate}

            fullWidth
            sx={{ marginTop: "20px" }}>
            <LocalizationProvider dateAdapter={AdapterDayjs}>
              <DatePicker
                id="joinDate"
                label="Join Date *"
                disabled={mode === "UPDATE"}
                // errorText = "This is an error message."
                value={joinDate}
                inputFormat="DD/MM/YYYY"
                onChange={(newValue) => {
                  setJoinDate(newValue);
                  console.log(newValue);
                }}
                renderInput={(params) => <TextField {...params} />}
                required
                // error={!validation.joinDate}
              />
            </LocalizationProvider>
          </FormControl>
          <FormControl fullWidth sx={{ marginTop: "20px" }}>
            <InputLabel id="plan-label" required>
              Plan
            </InputLabel>
            <Select
              labelId="plan-label"
              id="plan"
              value={plan}
              label="Plan"
              onChange={handleChangePlan}
              // error={!validation.plan}
              onClick={handleClickPlan}>
              <MenuItem value={"monthly"}>Monthly</MenuItem>
              <MenuItem value={"quarterly"}>Quarterly</MenuItem>
              <MenuItem value={"halfyearly"}>Half Yearly</MenuItem>
              <MenuItem value={"yearly"}>Yearly</MenuItem>
            </Select>
          </FormControl>
          <FormControl
            // error={!validation.renewalDate}
            fullWidth
            row
            sx={{
              marginTop: "20px",
              display: "flex",
              flexDirection: "row",
              justifyContent: "space-between",
            }}>
            <LocalizationProvider dateAdapter={AdapterDayjs}>
              <DatePicker
                id="renewalDate"
                label="Renewal Date *"
                value={renewalDate}
                inputFormat="DD/MM/YYYY"
                //readOnly = {mode === "ADD" ? true : false}
                onChange={(newValue) => {
                  setRenewalDate(newValue);
                }}
                renderInput={(params) => (
                  <TextField {...params} sx={{ flexGrow: 1 }} />
                )}
                required
                // errorText = "This is an error message."
              />
            </LocalizationProvider>
            {/* { mode === "UPDATE" && (
              <Tooltip title="Reset Plan & Renewal Date">
              <IconButton
                      aria-label="reset"
                      size="small"
                      onClick={handleResetRenewal}>
                      <RestoreIcon />
            </IconButton>
            </Tooltip>
            )} */}
          </FormControl>
          <TextField
            required
            disabled={mode === "UPDATE"}
            margin="dense"
            id="mobile"
            label="Mobile"
            fullWidth
            variant="standard"
            onChange={(e) => setMobile(e.target.value)}
            value={mobile}
            error={!validation.mobile}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>Cancel</Button>
          {mode === "ADD" && (
            <Button
              disabled={
                !(
                  name &&
                  age &&
                  gender &&
                  training &&
                  joinDate &&
                  plan &&
                  renewalDate &&
                  mobile
                ) || disableAddUpdate
              }
              onClick={() => {
                let validName = name && isValidName(name) ? true : false;
                let validAge = isValidAge(age);
                let validMobile = isValidMobileNumber(mobile);
                setValidation((prevState) => {
                  return {
                    name: validName,
                    age: validAge,
                    mobile: validMobile,
                  };
                });

                if (validName && validAge && validMobile) {
                  setDisableAddUpdate(true);
                  handleSave();
                }
              }}>
              Add
            </Button>
          )}
          {mode === "UPDATE" && (
            <Button
              disabled={
                !(
                  name &&
                  age &&
                  gender &&
                  training &&
                  joinDate &&
                  plan &&
                  renewalDate &&
                  mobile
                ) || disableAddUpdate || enableUpdateButton()
              }
              onClick={() => {
                let validName = name && isValidName(name) ? true : false;
                let validAge = isValidAge(age);
                let validMobile = isValidMobileNumber(mobile);
                setValidation((prevState) => {
                  return {
                    name: validName,
                    age: validAge,
                    mobile: validMobile,
                  };
                });
                // debugger;
                if (validName && validAge && validMobile) {
                  setDisableAddUpdate(true);
                  handleUpdate();
                }
              }}>
              Update
            </Button>
          )}
        </DialogActions>
      </Dialog>

      <Dialog
        fullScreen={fullScreen}
        open={openDelete}
        onClose={handleCloseDeleteDialog}
        aria-labelledby="responsive-dialog-title">
        <DialogTitle id="responsive-dialog-title">Delete User</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Are you want to delete this user ?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button autoFocus onClick={handleCloseDeleteDialog}>
            No
          </Button>
          <Button onClick={handleDeleteUser} autoFocus>
            Yes
          </Button>
        </DialogActions>
      </Dialog>

      <Dialog
        fullScreen={fullScreen}
        open={openV}
        onClose={handleCloseView}
        aria-labelledby="responsive-dialog-title">
        <DialogTitle id="responsive-dialog-title">
          You are viewing details of {name} :
        </DialogTitle>
        <DialogContent>
          <table>
            <tbody>
              <tr>
                <td>Name: </td>
                <td>{name}</td>
              </tr>
              <tr>
                <td>Age: </td>
                <td>{age}</td>
              </tr>
              <tr>
                <td>Gender: </td>
                <td>{gender}</td>
              </tr>
              <tr>
                <td>Training Category: </td>
                <td>{training}</td>
              </tr>
              <tr>
                <td>Join Date: </td>
                <td>{joinDate}</td>
              </tr>
              <tr>
                <td>Current Plan: </td>
                <td>{plan}</td>
              </tr>
              <tr>
                <td>Next Renewal: </td>
                <td>{renewalDate}</td>
              </tr>
              <tr>
                <td>Mobile: </td>
                <td>{mobile}</td>
              </tr>
            </tbody>
          </table>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseView}>Cancel</Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}
