import './App.css';
import Login from './admin/Login';
import Home from './Home';
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import Users from './admin/Users';
import Log from './pages/Log';
import QRScan from './admin/QRScan';
import Batch from './batch/Batch';

function App() {
  return (
      <Router>
      <div className="app">
        <Routes>
          <Route path="/login" element={<Login/>} />
          <Route path="/" element={<Home/>}/>
          <Route path="/users" element={<Users/>} />
          <Route path="/log" element={<Log/>} />
          {/* <Route path="/qrscan" element={<QRScan/>} /> */}
          <Route path="/batch" element={<Batch />} />
        </Routes>
      </div>
    </Router>
  );
}

export default App;
