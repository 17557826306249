import React from "react";
import { Link } from "react-scroll";

export const princingPlan = [
  {
    title: "Monthly",
    price: "2,500",
    frequency: "/ month",
    description: "Economic and friendly plan to start your fitness today.",
    features: [
      "Body analysis test 1",
      "General training",
      "General diet plan",
      "Cardio",
      "HIIT",
    ],
    mostpopular: false,
  },
  {
    title: "Quarterly",
    price: "6,000",
    frequency: "/ 3 months",
    description: "Economic and friendly plan to start your fitness today.",
    features: [
      "Body analysis test 2",
      "General training",
      "General diet plan",
      "Cardio",
      "HIIT",
    ],
    mostpopular: false,
  },
  {
    title: "Half Year",
    price: "8,500",
    frequency: "/ 6 months",
    description: "Economic and friendly plan to start your fitness today.",
    features: [
      "Body analysis test 3",
      "General training",
      "General diet plan",
      "Cardio",
      "HIIT",
    ],
    mostpopular: true,
  },
  {
    title: "Annual",
    price: "11,999",
    frequency: "/ year",
    description: "Economic and friendly plan to start your fitness today.",
    features: [
      "Body analysis test 4",
      "General training",
      "General diet plan",
      "Cardio",
      "HIIT",
    ],
    mostpopular: false,
  },
];
function Pricing() {
  return (
    <div id="General Training">
      <div className="container my-5 px-6 mx-auto">
        <section className="mb-3 text-gray-800 text-center">
          <div className="flex justify-center">
            <div className="max-w-[800px]">
              <h2 className="text-5xl md:text-6xl xl:text-7xl font-bold tracking-tight mb-2">
                <span className="text-red-600"> Are you ready </span>
                <br />
                for a Fitness adventure?
              </h2>
              <p className="text-slate-700 text-lg">
                start today with one of our amazing general training packs.
              </p>
            </div>
          </div>
        </section>
      </div>

      <div className="mx-auto grid max-w-7xl lg:grid-cols-4 gap-12 lg:gap-8 py-4 px-4 sm:px-6 lg:px-8">
        {princingPlan.map((plan) => (
          <div
            className="border border-slate-200 shadow-lg p-8 bg-white rounded-2xl relative flex flex-col"
            key={plan.title}>
            <h3 className="text-lg font-semibold">{plan.title} </h3>
            {plan.mostpopular && (
              <p className="absolute top-0 -translate-y-1/2 bg-red-500 text-white text-sm font-semibold -tracking-wide rounded-full px-3 py-0.5 shadow-md">
                Most Popular
              </p>
            )}

            <p className="mt-4 text-sm text-slate-700 leading-6">
              {plan.description}
            </p>
            <div
              className="mt-4 bg-slate-50 p-6 rounded-lg -mx-6 text-slate-500 
        flex"
              
              >
              <p>
                <span></span>
                <span className="text-4xl text-slate-900 ml-3">
                  {" "}
                  ₹{plan.price}
                </span>
                <span className="ml-1.5">{plan.frequency}</span>
              </p>
            </div>
            <ul className="mt-6 space-y-4 flex-1">
              {plan.features.map((feature) => (
                <li className="text-sm text-slate-700 flex" key={feature}>
                  {/* ÷\<TiTickOutline className='h-5 w-5 text-red-500 float-left shrink-0' /> */}
                  <svg
                    aria-hidden="true"
                    focusable="false"
                    data-prefix="fas"
                    data-icon="check"
                    className="text-red-600 w-4 h-4 mr-2 float-left shrink-0"
                    role="img"
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 512 512">
                    <path
                      fill="currentColor"
                      d="M173.898 439.404l-166.4-166.4c-9.997-9.997-9.997-26.206 0-36.204l36.203-36.204c9.997-9.998 26.207-9.998 36.204 0L192 312.69 432.095 72.596c9.997-9.997 26.207-9.997 36.204 0l36.203 36.204c9.997 9.997 9.997 26.206 0 36.204l-294.4 294.401c-9.998 9.997-26.207 9.997-36.204-.001z"></path>
                  </svg>
                  <span className="ml-3">{feature}</span>
                </li>
              ))}
            </ul>
            <Link
              to="Enquire Now"
              spy={true}
              smooth={true}
              offset={-50}
              duration={500}
              style={{ flexGrow: 1 }}>
              <span
                className={`cursor-pointer mt-8 block px-6 py-4  text-sm font-semibold leading-4 text-center rounded-lg shadow-md
        ${
          plan.mostpopular
            ? "text-white  bg-red-500  hover:bg-red-600"
            : "text-red-700 bg-red-50 hover:bg-red-100"
        }
        `}>
                Start your Trail
              </span>
            </Link>
          </div>
        ))}
      </div>
    </div>
  );
}
export default Pricing;
